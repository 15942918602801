import { useState } from "react";

const useBrowser = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const getToken = () => {
        try {
            const token = localStorage.getItem("token");
            if (token) {
                return token;
            }
        } catch (error) {
            console.log(error);
            return "";
        }
    }
    
    const [token, setToken] = useState(getToken());

    return {
        isSafari,
        token,
        setToken
    };
};

export default useBrowser;
