import React from "react";
import { useEffect } from "react";
import { getLeads, getSingleConversation } from "../../queries/conv";
import { getMentors } from "../../queries/mentor";
import { Configuration, OpenAIApi } from "openai";
import { Avatar, Chip, Tooltip } from "@mui/material";
import moment from "moment/moment";
import { IoLogoWhatsapp } from "react-icons/io5";
import Analysis from "./Analysis";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const Index = () => {
    const [state, globalDispatch] = useGlobalContext();

    const configuration = new Configuration({
        // organization: "org-T7MHqMSr8Yf7e7064Tfdm6aV",
        apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);

    useEffect(() => {
        if (!state.mentors) {
            globalDispatch({
                type: GActions.SET_MENTORS_LOADING,
                payload: true,
            });
            getMentors()
                .then((res) => {
                    globalDispatch({
                        type: GActions.SET_MENTORS,
                        payload: res,
                    });
                })
                .finally(() => {
                    globalDispatch({
                        type: GActions.SET_MENTORS_LOADING,
                        payload: false,
                    });
                });
        }

        if (!state.conversations) {
            globalDispatch({
                type: GActions.SET_CONVERSATION_LOADING,
                payload: true,
            });
            getLeads().then((res) => {
                // console.log(res);
                globalDispatch({
                    type: GActions.SET_CONVERSATIONS,
                    payload: res.leads,
                });
            });
            globalDispatch({
                type: GActions.SET_CONVERSATION_LOADING,
                payload: false,
            });
        }
    }, []);

    const onConvClick = (conv) => {
        console.log(conv._id + " id");
        console.log(conv.lastResponseStudent + " student");
        console.log(conv.lastResponseMentor + " mentor");
        globalDispatch({
            type: GActions.SET_SUMMARY,
            payload: null,
        });

        globalDispatch({
            type: GActions.SET_SELECTED_CONVERSATION,
            payload: conv,
        });
        const mentor = state.mentors?.find((m) => m._id === conv.mentorId);
        globalDispatch({
            type: GActions.SET_SELECTED_MENTOR,
            payload: mentor,
        });
    };

    const generateResponse = async (text) => {
        if (!configuration.apiKey) {
            return {
                error: {
                    message:
                        "OpenAI API key not configured, please follow instructions in README.md",
                },
            };
        }
        try {
            const words = 100;
            const response = await openai.createChatCompletion({
                model: "gpt-3.5-turbo",
                messages: [
                    {
                        role: "system",
                        content: `You are an AI language model, tasked to generate concise and accurate 
      summaries of conversations between prospective applicants and student ambassadors
       from different universities. The conversation data you will be given is an array
        of objects, where each object contains a 'sender' attribute indicating the origin
        of the message (either 'Applicant' or 'Ambassador'), and a 'message' attribute
         containing the text of the message. Your summaries must strictly adhere to
          the details provided in the given conversation without adding, speculating,
           or inferring any additional information.`,
                    },
                    {
                        role: "user",
                        content: `Generate a summary of the following conversation: '${text}'. The length of the summary should not exceed ${words} words.`,
                    },
                ],
            });
            const result = response.data.choices[0].message.content;
            return result;
        } catch (error) {
            if (error.response) {
                console.error(error.response.status, error.response.data);
                return error.response.data;
            } else {
                console.error(
                    `Error with OpenAI API request: ${error.message}`
                );
                return {
                    error: {
                        message: "An error occurred during your request.",
                    },
                };
            }
        }
    };

    const getSummary = async () => {
        let resultAI;
        let result;
        const convId = await state.selectedConversation?._id;
        console.log(convId);
        result = await getSingleConversation(convId);
        result = JSON.stringify(result);
        if (!result) {
            resultAI = "No messages were exchanged in this conversation";
        } else {
            resultAI = await generateResponse(result);
        }
        return resultAI;
    };

    const onGenerateAiSummaryButtonClick = async () => {
        globalDispatch({ type: GActions.SET_SUMMARY_LOADING, payload: true });
        try {
            const summary = await getSummary();

            globalDispatch({
                type: GActions.SET_SUMMARY,
                payload: summary,
            });
            globalDispatch({
                type: GActions.SET_SUMMARY_LOADING,
                payload: false,
            });
        } catch (error) {
            console.error("Error generating summary:", error);
            globalDispatch({
                type: GActions.SET_SUMMARY_LOADING,
                payload: false,
            });
        }
    };

    return (
        <div className="grid grid-cols-6 h-screen bg-gray-50">
            <div className="col-span-2 overflow-auto max-h-screen">
                <div className="ml-8 font-[Outfit] font-semibold text-4xl mt-6 mb-4">
                    Conversations
                </div>
                <div className="flex flex-col gap-2 mt-8">
                    {state.conversationLoading
                        ? "Loading..."
                        : state.conversations
                              ?.filter((c) => {
                                  if (state.user?.privilege === "admin")
                                      return true;

                                  return c.university === state.user?.privilege;
                              })
                              ?.map((conv, i) => (
                                  <ConvCard
                                      conv={conv}
                                      selected={
                                          conv._id ===
                                          state.selectedConversation?._id
                                      }
                                      prevSelected={
                                          state.conversations[i - 1]?._id ===
                                          state.selectedConversation?._id
                                      }
                                      key={conv._id}
                                      onClick={() => onConvClick(conv)}
                                  />
                              ))}
                </div>
            </div>
            <div className="col-span-4 px-6 py-4 h-full overflow-auto">
                <Analysis
                    onGenerateAiSummaryButtonClick={
                        onGenerateAiSummaryButtonClick
                    }
                />
            </div>
        </div>
    );
};

const ConvCard = ({
    conv,
    selected = false,
    onClick,
    prevSelected = false,
}) => {
    const responseStudent = conv?.lastResponseStudent;
    const responseMentor = conv?.lastResponseMentor;
    const lastResponseDateStudent = new Date(responseStudent);
    const lastResponseDateMentor = new Date(responseMentor);
    let finalTime;
    if (responseStudent === null && responseMentor === null) {
        finalTime = conv.createdAt;
    } else if (
        lastResponseDateMentor !== undefined &&
        lastResponseDateStudent !== undefined
    ) {
        if (lastResponseDateMentor > lastResponseDateStudent)
            finalTime = lastResponseDateMentor;
        else finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateMentor === undefined &&
        lastResponseDateStudent !== undefined
    ) {
        finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateStudent === undefined &&
        lastResponseDateMentor !== undefined
    ) {
        finalTime = lastResponseDateMentor;
    } else {
        finalTime = conv.createdAt;
    }

    const [tipText, setTipText] = React.useState("Copy");
    const [name, setName] = React.useState("");
    const [state] = useGlobalContext();
    useEffect(() => {
        const getMentorFromId = async (mentorId) => {
            try {
                const mentor = state.mentors.find((m) => m._id === mentorId);
                if (mentor) {
                    const mentorName = mentor.name;
                    setName(mentorName);
                }
            } catch (error) {
                console.error("Error retrieving mentor name:", error);
                return null;
            }
        };

        getMentorFromId(conv.mentorId);
    }, []);

    const avatarStyle = selected
        ? {
              backgroundColor: "#7C3AED",
              height: "50px",
              width: "50px",
          }
        : {
              height: "40px",
              width: "40px",
          };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setTipText("Copied!");
        setTimeout(() => {
            setTipText("Copy");
        }, 1000);
    };

    return (
        <div
            className={`pl-3 mr-1.5 ml-3 ${
                selected ? "py-5" : "py-3"
            } pr-1 cursor-pointer  relative hover:${
                selected ? "bg-white" : "bg-purple-50"
            } hover:shadow-[rgb(119_119_119)_0px_0px_4px] hover:rounded-md hover:bg-gray-200 transition-all ${
                selected
                    ? "bg-white border-r-8 my-2 border-purple-600 shadow-[rgba(0,_0,_0,_0.44)_0px_0px_4px] rounded-md"
                    : "border-b-[1px] border-gray-200 "
            } ${prevSelected ? "border-t-[1px] border-gray-200" : ""}}`}
            onClick={onClick}
        >
            <div className="flex gap-3">
                <Avatar style={avatarStyle}>
                    {conv?.name && typeof conv?.name === "string"
                        ? conv?.name[0]
                        : ""}
                </Avatar>
                <div className="flex flex-col">
                    <span className="font-[Outfit] font-medium text-lg leading-4">
                        {conv?.name}
                    </span>
                    <span className="ml-1 font-[Outfit] text-sm mt-1 text-gray-500">
                        with {name}
                    </span>
                    {selected && (
                        <div className="flex gap-1 mt-2">
                            <Tooltip title={tipText}>
                                <Chip
                                    icon={
                                        <IoLogoWhatsapp
                                            style={{
                                                color: "white",
                                                fontSize: "14px",
                                                marginLeft: "8px",
                                            }}
                                        />
                                    }
                                    label={conv.phone}
                                    variant="filled"
                                    style={{
                                        backgroundColor: "#128C7E",
                                        color: "white",
                                    }}
                                    size="small"
                                    onClick={() => copyToClipboard(conv.phone)}
                                    onMouseEnter={() => setTipText("Copy")}
                                />
                            </Tooltip>
                            {/* <Tooltip title={tipText}>
                                <Chip
                                    icon={
                                        <AiFillMail
                                            style={{
                                                color: "white",
                                                fontSize: "14px",
                                                marginLeft: "8px",
                                            }}
                                        />
                                    }
                                    label={conv.email}
                                    variant="filled"
                                    style={{
                                        backgroundColor: "rgb(205 92 92)",
                                        color: "white",
                                    }}
                                    size="small"
                                    onClick={() => copyToClipboard(conv.email)}
                                    onMouseEnter={() => setTipText("Copy")}
                                />
                            </Tooltip> */}
                        </div>
                    )}
                </div>
            </div>
            {/* {conv?.lastTimestamp ? (
        <div className="font-[Outfit] absolute top-1 right-2 text-xs text-gray-700">
          {moment(parseInt(conv?.lastTimestamp)).fromNow()}
        </div>
      ) : (
        <div className="font-[Outfit] absolute top-1 right-2 text-xs text-gray-700">
          {moment(parseInt(conv?.timestamp)).fromNow()}
        </div>
      )} */}
            <div className="font-[Outfit] absolute top-1 right-2 text-xs text-gray-700">
                {moment(finalTime).fromNow()}
                {/* {conv?.lastResponseStudent} */}
            </div>
        </div>
    );
};
export default Index;
