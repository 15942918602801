import { ArrowDownward, Sort as SortIcon } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { PropagateLoader } from "react-spinners";
import MentorTable from "./LeadTable";
import { FaUniversity } from "react-icons/fa";

import { Container, Heading, Header, ExportButton } from "./OverviewElements";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getLeads } from "../../queries/conv";
import { getMentorsByGroup } from "../../queries/mentor";
import { Sort, filterConversations, sortConversations } from "./sorter";
import { getGroups } from "../../queries/groups";

const Index = () => {
  const [state, globalDispatch] = useGlobalContext();
  const user = state.user;
  const [sortedQueries, setSortedQueries] = useState([]);
  const [isAscending, setIsAscending] = useState(false);
  const [sortBy, setSortBy] = useState("time");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [uniSelectMenu, setUniSelectMenu] = useState(null);
  const [currUni, setCurrUni] = useState(state.user?.privilege);
  const [leadsDataExcel, setLeadsDataExcel] = useState();
  const openMenu = (event) => {
    setMenuAnchor(event.target);
  };
  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const openUniSelectMenu = (event) => {
    setUniSelectMenu(event.target);
  };

  const closeUniSelectMenu = () => {
    setUniSelectMenu(null);
  };

  const handleUniSelectClick = (type) => {
    setCurrUni(type);
    console.log(type);
    if (type !== "admin") {
      const filtered = filterConversations(state.conversations, type);
      setSortedQueries(filtered);
    } else {
      setSortedQueries(state.conversations);
    }
    closeUniSelectMenu();
  };

  const handleMenuItemClick = (type) => {
    setSortBy(type);
    closeMenu();
  };

  const csvLink = useRef();

  // useEffect(() => {
  //     if (currUni.value === "admin") {
  //         setUniversity("");
  //     } else {
  //         setUniversity(currUni.value);
  //         localStorage.setItem("university", currUni.value);
  //     }
  // }, [currUni]);

  const dpFetchMentors = async () => {
    if (!state.mentors) {
      globalDispatch({
        type: GActions.SET_MENTORS_LOADING,
        payload: true,
      });
      const result = await getMentorsByGroup();
      console.log(result.message);
      globalDispatch({
        type: GActions.SET_MENTORS,
        payload: result.mentors,
      });
      globalDispatch({
        type: GActions.SET_MENTORS_LOADING,
        payload: false,
      });
      if (!result.ok) {
        globalDispatch({
          type: GActions.SET_SNACKBAR,
          payload: {
            open: true,
            message: result.message,
            severity: "error",
          },
        });
      }
    }
  };

  const dpFetchConversations = async () => {
    if (!state.conversations) {
      globalDispatch({
        type: GActions.SET_CONVERSATION_LOADING,
        payload: true,
      });
      const result = await getLeads();
      const leads = result.leads;

      const leadDataUniversity = leads.map((lead) => ({
        Name: lead.name,
        Email: lead.email,
        Cycle: lead.year,
        Mentor: lead.mentorName,
        "Last Response Mentor": lead.lastResponseMentor,
        "Last Response Student": lead.lastResponseStudent,
        "Created On": new Date(lead.createdAt),
      }));

      setLeadsDataExcel(leadDataUniversity);

      globalDispatch({
        type: GActions.SET_CONVERSATIONS,
        payload: result.leads,
      });
      globalDispatch({
        type: GActions.SET_CONVERSATION_LOADING,
        payload: false,
      });
      if (!result.ok) {
        globalDispatch({
          type: GActions.SET_SNACKBAR,
          payload: {
            open: true,
            message: result.message,
            severity: "error",
          },
        });
      }
    }
  };

  const fetchGroups = async () => {
    if (!state.groups) {
      globalDispatch({
        type: GActions.SET_GROUPS_LOADING,
        payload: true,
      });
      const result = await getGroups();
      console.log(result.message);
      console.log(result.groups);
      globalDispatch({
        type: GActions.SET_GROUPS,
        payload: result.groups,
      });
      globalDispatch({
        type: GActions.SET_GROUPS_LOADING,
        payload: false,
      });
      if (!result.ok) {
        globalDispatch({
          type: GActions.SET_SNACKBAR,
          payload: {
            open: true,
            message: result.message,
            severity: "error",
          },
        });
      }
    }
  };

  const fetchOverviewData = async () => {
    console.log("user: ", state.user?.privilege);
    await dpFetchMentors();
    if (state.user?.privilege === "admin") {
      await fetchGroups();
    }
    await dpFetchConversations();
  };

  useEffect(() => {
    fetchOverviewData();
  }, [state.user]);

  // sorting useEffect
  useEffect(() => {
    const sortedConversation = sortConversations(
      state.conversations,
      sortBy,
      isAscending
    );
    setSortedQueries(sortedConversation);
  }, [isAscending, sortBy, state.conversations]);

  const downloadCsvHandler = () => {
    csvLink.current.link.click();
  };

  return (
    <Container>
      <Header>
        <Heading>Overview</Heading>
        <div style={{ display: "flex" }}>
          {!state.conversationLoading && (
            <>
              <IconButton onClick={() => setIsAscending((prev) => !prev)}>
                <ArrowDownward
                  style={{
                    transition: "250ms",
                    transform: !isAscending ? "rotate(0deg)" : "rotate(180deg)",
                    color: "#1976d2",
                  }}
                />
              </IconButton>
              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                style={{
                  fontFamily: "Outfit",
                  textTransform: "capitalize",
                  marginRight: "10px",
                  width: "132px",
                  justifyContent: "start",
                }}
                onClick={openMenu}
              >
                Sort: {sortBy}
              </Button>
              <Button
                startIcon={<FaUniversity />}
                variant="contained"
                style={{
                  fontFamily: "Outfit",
                  textTransform: "capitalize",
                  marginRight: "10px",
                  justifyContent: "center",
                  color: "white",
                  borderColor: "gray",
                  width: "160px",
                  backgroundColor: "#817f7f",
                }}
                disabled={user?.privilege !== "admin"}
                onClick={openUniSelectMenu}
              >
                {currUni === "admin" ? "All" : currUni}
              </Button>
              <ExportButton onClick={downloadCsvHandler}>
                Export to Excel
              </ExportButton>
              <CSVLink
                data={leadsDataExcel || []}
                ref={csvLink}
                target="_blank"
                filename="leads.csv"
              />
            </>
          )}
        </div>
      </Header>
      {state.conversationLoading ? (
        <Loading />
      ) : (
        <MentorTable leads={sortedQueries} userType={state.user?.privilege} />
      )}

      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={closeMenu}
      >
        <MenuItem
          sx={{ width: "132px", fontFamily: "Outfit" }}
          onClick={() => handleMenuItemClick(Sort.NAME)}
        >
          Name
        </MenuItem>
        <MenuItem
          sx={{ width: "132px", fontFamily: "Outfit" }}
          onClick={() => handleMenuItemClick(Sort.EMAIL)}
        >
          Email
        </MenuItem>
        <MenuItem
          sx={{ width: "132px", fontFamily: "Outfit" }}
          onClick={() => handleMenuItemClick(Sort.TIME)}
        >
          Time
        </MenuItem>
        <MenuItem
          sx={{ width: "132px", fontFamily: "Outfit" }}
          onClick={() => handleMenuItemClick(Sort.CYCLE)}
        >
          Cycle
        </MenuItem>
      </Menu>

      {/* Uni select menu */}
      <Menu
        open={Boolean(uniSelectMenu)}
        anchorEl={uniSelectMenu}
        onClose={closeUniSelectMenu}
      >
        {user?.privilege === "admin" && !state.groupsLoading ? (
          state.groups.map((group) => (
            <MenuItem
              key={group._id}
              sx={{
                width: "160px",
                fontFamily: "Outfit",
                textTransform: "capitalize",
              }}
              onClick={() => handleUniSelectClick(group.name)}
            >
              {group.name === "admin" ? "All" : group.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            sx={{
              width: "160px",
              fontFamily: "Outfit",
              textTransform: "capitalize",
            }}
            disabled
          >
            {user?.privilege}
          </MenuItem>
        )}
      </Menu>
    </Container>
  );
};

export const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "400px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PropagateLoader color="#b200f1" />
    </div>
  );
};
export default Index;
