export const Sort = {
    EMAIL: "email",
    TIME: "time",
    NAME: "name",
    CYCLE: "cycle",
};

export const filterConversations = (conversations, filter) => {
    if (!conversations || conversations.length === 0) return [];
    let filtered = [...conversations];
    if (filter === "") return filtered;
    return filtered.filter(
        (conversation) => conversation.university === filter
    );
};

export const sortConversations = (conversations, sortBy, isAscending) => {
    if (!conversations || conversations.length === 0) return [];
    let sorted = [...conversations];
    const order = !isAscending ? 1 : -1;

    switch (sortBy) {
        case Sort.TIME:
            sorted.sort((a, b) => {
                return (
                    order *
                    (new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime())
                );
            });
            break;
        case Sort.NAME:
            sorted.sort((a, b) => {
                const aName = a.name?.toLowerCase() || "";
                const bName = b.name?.toLowerCase() || "";

                if (aName > bName) return order * 1;
                else if (aName < bName) return order * -1;
                else return 0;
            });
            break;
        case Sort.EMAIL:
            sorted.sort((a, b) => {
                const aName = a.email?.toLowerCase() || "";
                const bName = b.email?.toLowerCase() || "";

                if (aName > bName) return order * 1;
                else if (aName < bName) return order * -1;
                else return 0;
            });
            break;
        case Sort.CYCLE:
            sorted.sort((a, b) => {
                let aYear = parseInt(a.year);
                let bYear = parseInt(b.year);
                if (isNaN(aYear)) {
                    aYear = a.year.match(/(\d+)/)[0];
                    aYear = parseInt(aYear);
                }
                if (isNaN(bYear)) {
                    bYear = b.year.match(/(\d+)/)[0];
                    bYear = parseInt(bYear);
                }
                return order * (aYear - bYear);
            });
            break;
        default:
            break;
    }

    return sorted;
};
