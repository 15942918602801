import axios from "axios";
import { BASE_URL } from "./url";
import token from "./token";

const mApi = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getGroups = async () => {
    try {
        const result = await mApi.get("/groups", {
            params: {
                token,
            },
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getGroup = async (id) => {
    try {
        const result = await mApi.get(`/groups/${id}`, {
            params: {
                token,
            },
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const addGroup = async (group) => {
    try {
        const result = await mApi.post("/groups", { ...group, token });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
