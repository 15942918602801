import { Badge, Collapse, Fade } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
    MoreUniversities,
    Table,
    UniversityCellCollapsed,
    UniWrapper,
} from "./OverviewElements";
import { TransitionGroup } from "react-transition-group";
import LeadTablePagination from "./LeadTablePagination";
import moment from "moment";

const LeadTable = ({ leads = [], userType = "" }) => {
    const rowsPerPage = 7;
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(leads.length);

    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        setTotalPage(Math.ceil(leads.length / rowsPerPage));
    }, [leads]);

    const handleNextPageClick = () => {
        if (currentPage === totalPage - 1) return;
        setCurrentPage((prev) => prev + 1);
    };

    const handlePrevPageClick = () => {
        if (currentPage === 0) return;
        setCurrentPage((prev) => prev - 1);
    };

    const toggleExpand = (index) => {
        let newExpanded = [...expanded];
        if (newExpanded.includes(index)) {
            newExpanded = newExpanded.filter((ele) => ele !== index);
            setExpanded(newExpanded);
        } else {
            newExpanded.push(index);
            setExpanded(newExpanded);
        }
    };

    return (
        <>
            <Table.Wrapper style={{ marginTop: "16px" }}>
                <thead>
                    <tr style={{ borderBottom: "1px solid #d2d2d2" }}>
                        <Table.Head>Name</Table.Head>
                        <Table.Head>Email</Table.Head>
                        {userType === "admin" && (
                            <Table.Head>University</Table.Head>
                        )}
                        <Table.Head>Cycle</Table.Head>
                        <Table.Head>Mentor</Table.Head>
                        <Table.Head>Last Response (Mentor)</Table.Head>
                        <Table.Head>Last Response (Student)</Table.Head>
                        <Table.Head>Created On</Table.Head>
                    </tr>
                </thead>
                <tbody>
                    {leads
                        .slice(
                            currentPage * rowsPerPage,
                            currentPage * rowsPerPage + rowsPerPage
                        )
                        .map((lead, index) => {
                            const isExpanded = expanded.includes(lead._id);
                            return (
                                <Table.Row
                                    key={lead._id}
                                    onClick={() => toggleExpand(lead._id)}
                                >
                                    <Table.Data style={{ width: "15%" }}>
                                        {lead.name}
                                    </Table.Data>
                                    <Table.Data>{lead.email}</Table.Data>
                                    {userType === "admin" && (
                                        <Table.Data>
                                            <UniversityCell
                                                expanded={isExpanded}
                                                id={lead._id}
                                                universities={lead.university}
                                            />
                                        </Table.Data>
                                    )}
                                    <Table.Data>{lead.year}</Table.Data>
                                    <Table.Data>{lead.mentorName}</Table.Data>
                                    <Table.Data>
                                        {lead.lastResponseMentor
                                            ? moment
                                                  .utc(lead.lastResponseMentor)
                                                  .local()
                                                  .format("Do MMM, YYYY")
                                            : "N/A"}
                                    </Table.Data>
                                    <Table.Data>
                                        {lead.lastResponseStudent
                                            ? moment
                                                  .utc(lead.lastResponseStudent)
                                                  .local()
                                                  .format("Do MMM, YYYY")
                                            : "N/A"}
                                    </Table.Data>

                                    <Table.Data>
                                        {moment
                                            .utc(lead.createdAt)
                                            .local()
                                            .format("Do MMM, YYYY")}
                                    </Table.Data>
                                </Table.Row>
                            );
                        })}
                </tbody>
            </Table.Wrapper>
            <LeadTablePagination
                currentPage={currentPage + 1}
                totalPage={totalPage}
                onPreviousClick={() => handlePrevPageClick()}
                onNextClick={() => handleNextPageClick()}
            />
        </>
    );
};

const UniversityCell = ({ universities = [], id, expanded }) => {
    // const [expanded, setExpanded] = useState(false);

    if (typeof universities === "string")
        return <UniWrapper>{universities}</UniWrapper>;

    if (universities.length === 0) return null;
    if (universities.length === 1)
        return <UniWrapper>{universities[0]}</UniWrapper>;

    return (
        <TransitionGroup
            // onClick={() => setExpanded(!expanded)}
            style={{ display: "flex", flexWrap: "wrap" }}
        >
            {!expanded ? (
                <Collapse>
                    <UniversityCellCollapsed>
                        <UniWrapper
                            style={{
                                marginTop: "10px",
                                marginRight: "10px",
                                boxSizing: "content-box",
                            }}
                        >
                            {universities[0]}
                            <MoreUniversities>
                                +{universities.length - 1}
                            </MoreUniversities>
                        </UniWrapper>
                    </UniversityCellCollapsed>
                </Collapse>
            ) : (
                universities.map((uni, index) => (
                    <Collapse key={`${id}-${index}-${uni}`}>
                        <UniWrapper>{uni}</UniWrapper>
                    </Collapse>
                ))
            )}
        </TransitionGroup>
    );
};

export default LeadTable;
