import styled from "@emotion/styled";
import { keyframes } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useEffect } from "react";
import { MdContentCopy } from "react-icons/md";
import { useGlobalContext } from "../../contexts/GlobalContext";

const AiSummary = ({ onGenerateAiSummaryButtonClick }) => {
    const [state] = useGlobalContext();

    if (!state.selectedConversation) return null;

    if (state.summaryLoading)
        return (
            <div className="mt-4 p-4 bg-gray-100 h-[180px] flex flex-col gap-2 border-2 rounded-lg relative">
                <div className="w-[92%] animate-pulse ml-auto bg-gray-300 h-[16px] rounded-[5px]"></div>
                <div className="w-full animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                <div className="w-full animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                <div className="flex gap-2">
                    <div className="w-[30%] animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                    <div className="flex-grow animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                </div>
                <div className="w-full animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                <div className="flex gap-2">
                    <div className="w-[80%] animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                    <div className="flex-grow animate-pulse bg-gray-300 h-[16px] rounded-[5px]"></div>
                </div>

                <div className="absolute flex  items-center gap-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#fe2170] px-4 py-1 rounded-lg">
                    <AiOutlineLoading3Quarters
                        className="animate-spin"
                        style={{ color: "white" }}
                    />
                    <span className="font-[Outfit] text-sm text-white">
                        Working on it
                    </span>
                </div>
            </div>
        );

    if (!state.summary)
        return (
            <div className="w-full flex flex-col mt-8 items-center">
                <GenerateButton onClick={onGenerateAiSummaryButtonClick} />

                <div className="font-[Outfit] font-medium w-7/12 leading-5 mt-6 text-center text-[#777] text-[16px]">
                    Generate a summary of this conversation using AI powered by
                    GPT-4 and OpenAI
                </div>
            </div>
        );

    return <TypeWritten text={state.summary} />;
};

const TypeWritten = ({ text }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentWord, setCurrentWord] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentWord === text.split(" ").length) {
                clearInterval(interval);
            } else {
                if (
                    currentWord % 7 === 0 &&
                    text.split(" ").length - currentWord > 7
                ) {
                    let newAppend = " ";
                    for (let i = 0; i < 3; i++) {
                        newAppend =
                            newAppend + " " + text.split(" ")[currentWord + i];
                    }
                    setDisplayText(
                        (prevDisplayText) => prevDisplayText + newAppend
                    );
                    setCurrentWord((prevIndex) => prevIndex + 3);
                } else {
                    setDisplayText(
                        (prevDisplayText) =>
                            prevDisplayText + " " + text.split(" ")[currentWord]
                    );
                    setCurrentWord((prevIndex) => prevIndex + 1);
                }
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [currentWord, text]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <>
            <div className="font-[Outfit] mt-4 border-2 bg-gray-100 p-4 rounded-md">
                {displayText}
            </div>
            {currentWord === text.split(" ").length && (
                <Button
                    startIcon={<MdContentCopy />}
                    style={{ marginTop: "12px" }}
                    onClick={copyToClipboard}
                >
                    Copy
                </Button>
            )}
        </>
    );
};

const GenerateButton = ({ onClick }) => {
    const [hover, setHover] = useState(false);
    return (
        <ButtonSkeletal
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
        >
            <FaWandMagicSparkles
                style={{ color: hover ? "white" : "#fe2170" }}
                className=""
            />
            Generate
        </ButtonSkeletal>
    );
};
const linearGradientKeyframes = keyframes`
    0% {
        backgroundPosition: "0% 50%",
    }
    50% {
        backgroundPosition: "100% 50%",
    }
    100% {
        backgroundPosition: "0% 50%",
    }`;

const ButtonSkeletal = styled(Button)`
    text-transform: none !important;
    font-size: 20px;
    font-weight: 400;
    align-items: center;
    display: flex;
    gap: 12px;
    border: 2px solid #fe2170aa !important;
    border-radius: 12px !important;
    padding: 4px 18px !important;
    background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
    animation: ${linearGradientKeyframes} 1s ease infinite;
    color: #f32170;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    width: 200px;

    &:hover {
        background: #f32170;
        color: white;
        -webkit-text-fill-color: white;
    }
`;

export default AiSummary;
