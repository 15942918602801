import { NavigateNext } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import {
    getUserQuery,
    loginQuery,
    setInitialPasswordQuery,
    initialPasswordAsDefaultQuery,
} from "../../queries/login";
import PageLoading from "../Loading";
import ActualLoginForm from "./ActualLoginForm";
import Continue from "./Continue";
import FirstTimePasswordSet from "./FirstTimePasswordSet";
import {
    Branding,
    BrandingInfo,
    BrandingSubtitle,
    BrandingTitle,
    GradientBackground,
    ImageLogo,
    LoginCard,
} from "./items";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import useBrowser from "../../utils/useBrowser";

const Login = () => {
    const [loading, setLoading] = useState(true);
    const [showPasswordSet, setShowPasswordSet] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [error, setError] = useState({
        email: "",
        password: "",
    });

    const history = useHistory();
    const [state, globalDispatch] = useGlobalContext();

    const { setToken } = useBrowser();

    const handleLoginFormSubmit = async (form) => {
        const result = await loginQuery(form);
        if (!result?.ok) {
            setError({ email: "", password: "" });
            result.results?.forEach((item) => {
                setError((prev) => ({
                    ...prev,
                    [item.field]: item.message,
                }));
            });
        } else {
            console.log("login success", result);

            localStorage.setItem("token", result.token);
            setToken(result.token);

            const query = await getUserQuery(result.token);
            console.log(query);
            // auth.setUser(query?.user);
            globalDispatch({ type: GActions.SET_USER, payload: query?.user });
            localStorage.setItem("user", JSON.stringify(query?.user));

            if (!query?.user?.initialPasswordReset) {
                setShowPasswordSet(true);
            } else {
                history.push("/");
            }
        }
    };

    const handleNewPasswordSetSubmit = async (newPassword) => {
        const passwordForm = { password: newPassword };
        console.log(passwordForm);

        const result = await setInitialPasswordQuery(passwordForm);
        console.log("password set result", result);
        if (result.ok) {
            setPasswordChanged(true);
        }
    };

    const handleUseDefaultPassword = async () => {
        const result = await initialPasswordAsDefaultQuery();
        console.log("use default result", result);
        history.push("/");
    };

    useEffect(() => {
        console.log(state.user);
        if (state.user) {
            history.push("/");
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return (
            <PageLoading>
                <PropagateLoader />
            </PageLoading>
        );
    }
    const goToDashboard = () => {
        history.push("/");
    };

    const rightSideStuff = () => {
        if (passwordChanged) return <Continue goToDashboard={goToDashboard} />;
        if (showPasswordSet)
            return (
                <FirstTimePasswordSet
                    onSubmit={handleNewPasswordSetSubmit}
                    onUseDefaultPassword={handleUseDefaultPassword}
                />
            );
        return (
            <ActualLoginForm onSubmit={handleLoginFormSubmit} error={error} />
        );
    };

    const leftSideStuff = () => {
        if (passwordChanged) return "Password changed successfully";
        if (showPasswordSet)
            return `You are logging in for the first time using the default password. We
    recommend you to change your password.`;

        return (
            <>
                {" "}
                Sign in to continue <NavigateNext />
            </>
        );
    };

    return (
        <GradientBackground>
            <LoginCard>
                <Branding>
                    <ImageLogo src="/icons/collegeit_logo.png" />
                    <BrandingTitle>Collegeit Platform</BrandingTitle>
                    <BrandingSubtitle>Manage your leads.</BrandingSubtitle>
                    <BrandingInfo>{leftSideStuff()}</BrandingInfo>
                </Branding>
                {rightSideStuff()}
            </LoginCard>
        </GradientBackground>
    );
};

export default Login;
