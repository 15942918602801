import React from "react";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import { TwoPlane } from "./NewMentorElements";
import NewMentorForm from "./NewMentorForm";
import Preview from "./Preview";
import { addMentor } from "../../queries/mentor";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const initalState = {
    name: "",
    email: "",
    phone: "",
    university: "",
    university_mentor: "",
    linkedin: "",
    bio: "",
    country: "",
    specialization: "",
    img_url_web: "",
    img_url_mobile: "",
    availability: "",
    test_mentor: false,
};

const NewMentor = () => {
    const [submitting, setSubmitting] = React.useState(false);
    const [state, globalDispatch] = useGlobalContext();
    const [mentor, setMentor] = React.useState(
        state.user?.privilege === "admin"
            ? initalState
            : {
                  ...initalState,
                  university_mentor: state.user?.privilege,
                  university: state.user.universityName,
              }
    );

    const onMentorFormSubmit = async (e) => {
        e.preventDefault();
        console.log(mentor);
        setSubmitting(true);
        try {
            const res = await addMentor(mentor);
            console.log(res);
            if (res.ok) {
                globalDispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        message: res.message,
                        severity: "success",
                    },
                });

                let oldMnetors = [...state.mentors];
                oldMnetors.push(res.mentor);
                oldMnetors?.sort((a, b) => a.name.localeCompare(b.name));

                globalDispatch({
                    type: GActions.SET_MENTORS,
                    payload: oldMnetors,
                });

                setMentor(initalState);
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
            globalDispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    message:
                        err?.response?.data?.message || "Something went wrong",
                    severity: "error",
                },
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            <Header>
                <Heading style={{ display: "flex", alignItems: "center" }}>
                    <BackButton />
                    Add mentor
                </Heading>
            </Header>
            <TwoPlane>
                <NewMentorForm
                    mentor={mentor}
                    setMentor={setMentor}
                    onFormSubmit={onMentorFormSubmit}
                    submitting={submitting}
                />
                <Preview mentor={mentor} />
            </TwoPlane>
        </Container>
    );
};

export const BackButton = ({ link = "/mentors" }) => {
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };
    return (
        // <Link to={link}>
        <IconButton onClick={goBack}>
            <MdOutlineArrowBackIosNew
                style={{
                    fontSize: "30px",
                    color: "#5f00d8",
                }}
            />
        </IconButton>
        // </Link>
    );
};

export default NewMentor;
