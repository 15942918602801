import axios from "axios";
import { SERVICE_URL, BASE_URL } from "./url";
import token from "./token";

const apiService = axios.create({
    baseURL: SERVICE_URL,
});

const platformApi = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export const addMentor = (mentor) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await platformApi.post("/mentors/new", {
                ...mentor,
                token,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });

export const updateMentor = (mentor) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await platformApi.post("/mentors/update", {
                ...mentor,
                token,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });

export const toggleMentorVisibility = (mentorId) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await platformApi.post(`/mentors/toggleActive`, {
                _id: mentorId,
                token,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });

export const deleteMentorById = (mentorId) =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await platformApi.post(`/mentors/delete`, {
                _id: mentorId,
                token,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });

export const getMentorsByGroup = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await platformApi.get("/mentors", {
                params: {
                    token,
                },
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getMentors = () =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await apiService.get("/mentors/info");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
