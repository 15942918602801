import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import items from "./selectData";
import {
    FilterActions,
    useMentorFilters,
} from "../../contexts/MentorFilterContext";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getGroups } from "../../queries/groups";

const UniSelect = ({ filterMentors = () => {} }) => {
    const [filter, dispatch] = useMentorFilters();
    const [state, globalDispatch] = useGlobalContext();
    const [uniOption, setUniOption] = React.useState(state.user?.privilege);

    React.useEffect(() => {
        if (state.user?.privilege === "admin" && !state.groups) {
            getGroups().then((res) => {
                globalDispatch({
                    type: GActions.SET_GROUPS,
                    payload: res.groups,
                });
            });
        }
    }, []);

    React.useEffect(() => {
        console.log("state.mentors use effect", uniOption);
        filterMentors({ ...filter.mentorFilter, university: uniOption });
    }, [state.mentors]);

    if (!state.user?.privilege) return null;

    if (!state.groups) return null;

    const handleChange = (e) => {
        const value = e.target.value;
        dispatch({
            type: FilterActions.SET_MENTOR_FILTER,
            payload: { ...filter.mentorFilter, university: value },
        });
        filterMentors({ ...filter.mentorFilter, university: value });
        setUniOption(value);
    };

    return (
        <FormControl>
            <Select
                onChange={handleChange}
                size="small"
                value={filter.mentorFilter.university}
                style={{
                    minWidth: "140px",
                    borderRadius: "50px",
                }}
                SelectDisplayProps={{
                    style: { paddingTop: "4px", paddingBottom: "4px" },
                }}
            >
                {state.groups.map((it) => (
                    <MenuItem
                        value={it.name === "admin" ? "all" : it.name}
                        key={it._id}
                    >
                        {it.name === "admin" ? "All" : it.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default UniSelect;
