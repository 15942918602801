import { useContext } from "react";
import { useReducer } from "react";
import { createContext } from "react";
import { useGlobalContext } from "./GlobalContext";

const initialState = {
    mentorFilter: {
        name: "",
        specialization: "",
        university: "all",
        test_mentor: false,
        test_mentor_value: "",
    },
    mentorFilterList: [],
    mentorFilterListLoading: false,
};

const MentorFilterContext = createContext();

export const FilterActions = {
    SET_MENTOR_FILTER: "SET_MENTOR_FILTER",
    SET_MENTOR_FILTER_LIST: "SET_MENTOR_FILTER_LIST",
    SET_MENTOR_FILTER_LIST_LOADING: "SET_MENTOR_FILTER_LIST_LOADING",
};

const MentorFilterReducer = (state, action) => {
    switch (action.type) {
        case FilterActions.SET_MENTOR_FILTER:
            return {
                ...state,
                mentorFilter: action.payload,
            };
        case FilterActions.SET_MENTOR_FILTER_LIST:
            return {
                ...state,
                mentorFilterList: action.payload,
            };
        case FilterActions.SET_MENTOR_FILTER_LIST_LOADING:
            return {
                ...state,
                mentorFilterListLoading: action.payload,
            };
        default:
            return state;
    }
};

export const MentorFilterProvider = ({ children }) => {
    const [globalState] = useGlobalContext();
    if (globalState.user?.privilege === "admin") {
        initialState.mentorFilter.university = "all";
    } else {
        initialState.mentorFilter.university = globalState.user?.privilege;
    }
    const [state, dispatch] = useReducer(MentorFilterReducer, initialState);

    return (
        <MentorFilterContext.Provider value={[state, dispatch]}>
            {children}
        </MentorFilterContext.Provider>
    );
};

export const useMentorFilters = () => {
    return useContext(MentorFilterContext);
};

export default MentorFilterContext;
