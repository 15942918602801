import axios from "axios";
import { BASE_URL } from "./url";
import token from "./token";

const loginAxios = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getConvs = async () => {
    try {
        const result = await loginAxios.get(`/all`, {
            params: {
                token,
            },
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getSingleConversation = async (convId) => {
    try {
        const result = await loginAxios.get(`/conversation/${convId}`, {
            params: {
                token,
            },
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getLeads = async () => {
    try {
        const result = await loginAxios.get("/leads/", {
            params: {
                token,
            },
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
