import styled from "@emotion/styled";
import { IconButton, Button } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import { AiFillLinkedin, AiOutlineClose, AiTwotoneMail } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { FaUniversity } from "react-icons/fa";
import { MdEventAvailable, MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";

const MentorInfo = ({
    showUni = true,
    mentor,
    onClose,
    toggleVisibility = () => {},
    deleteMentor = () => {},
}) => {
    const [visDisabled, setVisDisabled] = React.useState(false);
    const [isHidden, setIsHidden] = React.useState(
        mentor?.test_mentor || false
    );

    const handleToggleVisibility = () => {
        toggleVisibility(mentor, setIsHidden, setVisDisabled);
    };

    return (
        <Cover>
            <ProfilePic src={mentor?.img_url_web || "/avatar.webp"} alt="img" />
            <Details>
                <Name>{mentor?.name}</Name>
                <Specialization>{mentor?.specialization}</Specialization>
                <hr />

                <InfoChunk
                    icon={<BsTelephoneFill className="mr-2" />}
                    data={mentor?.phone}
                    cn="mt-2"
                />

                <InfoChunk
                    icon={<AiTwotoneMail className="mr-2" />}
                    data={mentor?.email}
                />

                <InfoChunk
                    icon={<FaUniversity className="mr-2" />}
                    data={mentor?.university}
                />

                <InfoChunk
                    icon={<AiFillLinkedin className="mr-2" />}
                    data={
                        <a
                            href={mentor?.linkedin}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" "}
                            {mentor?.linkedin}{" "}
                        </a>
                    }
                />

                <InfoChunk
                    icon={<MdEventAvailable className="mr-2" />}
                    data={mentor?.availability || "Availability not mentioned"}
                />

                <InfoChunk
                    icon={<MdVisibility className="mr-2" />}
                    data={isHidden ? "Hidden" : "Visible"}
                />

                {false && (
                    <Specialization
                        style={{
                            bottom: "7px",
                            right: "10px",
                            position: "absolute",
                            fontStyle: "italic",
                            fontSize: "12px",
                        }}
                    >
                        Last active {moment(mentor.lastTimestamp).fromNow()}
                    </Specialization>
                )}

                <div className="flex gap-2 mt-4">
                    <Button
                        disabled={visDisabled}
                        onClick={handleToggleVisibility}
                    >
                        {isHidden ? "Show Mentor" : "Hide Mentor"}
                    </Button>
                    <Link to={"/mentors/" + mentor?._id}>
                        <Button>Edit</Button>
                    </Link>
                    <Button onClick={() => deleteMentor(mentor)}>Delete</Button>
                </div>
            </Details>
            <IconButton
                style={{ position: "absolute", top: "2px", right: "2px" }}
                onClick={onClose}
            >
                <AiOutlineClose style={{ fontSize: "22px", color: "black" }} />
            </IconButton>
        </Cover>
    );
};

const InfoChunk = ({ icon, data, cn = "" }) => {
    return (
        <div className={"flex items-center gap-2 " + cn}>
            {icon}
            <Specialization
                style={{
                    color: "black",
                    fontSize: "17px",
                }}
            >
                {data}
            </Specialization>
        </div>
    );
};

const Cover = styled.div`
    background: white;
    min-width: 700px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    padding: 22px 28px;
    padding-bottom: 24px;
    display: flex;
    gap: 12px;
    position: relative;
    transition: 100ms all ease-out;
    border-radius: 12px;
    user-select: none;
    &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #fafafa;
    }
`;

const ProfilePic = styled.img`
    width: 190px;
    height: 190px;
    border-radius: 50%;
`;

const Details = styled.div`
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    padding: 4px 0;
    gap: 3px;
`;

const Name = styled.span`
    font-size: 30px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 1;
`;

const Specialization = styled.span`
    font-size: 19px;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    color: #7a7a7a;
`;

export default MentorInfo;
