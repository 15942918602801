let token = "";

export const updateToken = () => {
    try {
        token = localStorage.getItem("token");
        console.log("Token: ", token);
        if (token) {
            return token;
        }
    } catch (error) {
        console.log(error);
        return "";
    }
};

updateToken();

export default token;
