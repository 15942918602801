// export const BASE_URL = "http://localhost:3000/dev";
export const BASE_URL =
    "https://21994a3w0f.execute-api.ap-south-1.amazonaws.com/dev/";

export const MEET_URL =
    "https://kw41fi5rla.execute-api.ap-south-1.amazonaws.com/";

export const SERVICE_URL =
    "https://sh63i7vbx5.execute-api.ap-south-1.amazonaws.com/";

// export const IMG_UPLOAD_URL = "http://image.ap-south-1.elasticbeanstalk.com/";
export const IMG_UPLOAD_URL =
    "https://upload-image-production-fac4.up.railway.app";
