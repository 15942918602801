import Overview from "../components/Overview";

const OverviewPage = () => {
  return (
    <>
      <Overview />
    </>
  );
};

export default OverviewPage;
