import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageLoading from "../Loading";
import { PropagateLoader } from "react-spinners";
import SendPasswordResetEmail from "./SendPasswordResetEmail";
import VerifyResetCode from "./VerifyResetCode";
import PasswordReset from "./PasswordReset";
import GoodToGo from "./GoodToGo";
import {
    Branding,
    BrandingInfo,
    BrandingSubtitle,
    BrandingTitle,
    GradientBackground,
    ImageLogo,
    LoginCard,
} from "./items";
import {
    changePasswordQuery,
    initiateForgotPasswordQuery,
    verifyForgotPasswordQuery,
} from "../../queries/login";
import { useGlobalContext } from "../../contexts/GlobalContext";

const ForgotPassword = () => {
    const [loading, setLoading] = useState(true);
    const [verifiedEmail, setVerifiedEmail] = useState("");
    const [verifiedCode, setVerifiedCode] = useState("");
    const [sentEmail, setSentEmail] = useState(false);
    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);

    const history = useHistory();
    const [state] = useGlobalContext();

    useEffect(() => {
        console.log(state.user);
        if (state.user) {
            history.push("/");
        } else {
            setLoading(false);
        }
    }, [state, history]);

    if (loading)
        return (
            <PageLoading>
                <PropagateLoader />
            </PageLoading>
        );

    const handleEmailSubmit = async (
        email,
        setError,
        setEmailSubmitLoading
    ) => {
        if (!email || email.trim() === "") {
            setError({ email: "Enter a valid email" });
            return;
        }
        setEmailSubmitLoading(true);

        const result = await initiateForgotPasswordQuery(email);

        setEmailSubmitLoading(false);
        if (result.ok) {
            setVerifiedEmail(email);
            setSentEmail(true);
        } else {
            setError({ email: result?.message });
        }
    };

    const handleCodeSubmit = async (code, setError, setCodeLoading) => {
        if (!code || code.trim() === "") {
            setError({ code: "Code cannot be blank" });
            return;
        }
        if (code?.length !== 6) {
            setError({ code: "Code should be 6 digits long" });
            return;
        }
        setCodeLoading(true);
        const result = await verifyForgotPasswordQuery(verifiedEmail, code);
        setCodeLoading(false);
        if (result.ok) {
            setVerifiedCode(code);
            setIsCodeVerified(true);
        } else {
            setError({ code: result.message });
        }
    };

    const handleChangePasswordSubmit = async (
        newPassword,
        setError,
        setFormLoading
    ) => {
        setFormLoading(true);
        const result = await changePasswordQuery({
            newPassword,
            email: verifiedEmail,
            resetCode: verifiedCode,
        });
        setFormLoading(false);
        if (result.ok) {
            setPasswordReset(true);
        } else {
            setError({ newPassword: result.message });
        }
    };

    const rightSideStuff = () => {
        if (!sentEmail)
            return <SendPasswordResetEmail onSubmit={handleEmailSubmit} />;
        if (sentEmail && !isCodeVerified)
            return <VerifyResetCode onSubmit={handleCodeSubmit} />;
        if (sentEmail && isCodeVerified && !passwordReset)
            return <PasswordReset onSubmit={handleChangePasswordSubmit} />;

        return <GoodToGo />;
    };
    const leftSideStuff = () => {
        if (!sentEmail) return "Reset Password";
        if (sentEmail && !isCodeVerified)
            return "Check your email for verficiation code";
        if (sentEmail && isCodeVerified && !passwordReset)
            return "Set new password";
    };

    return (
        <GradientBackground>
            <LoginCard>
                <Branding>
                    <ImageLogo src="/icons/collegeit_logo.png" />
                    <BrandingTitle>Collegeit Platform</BrandingTitle>
                    <BrandingSubtitle>Manage your leads.</BrandingSubtitle>
                    <BrandingInfo>{leftSideStuff()}</BrandingInfo>
                </Branding>
                {rightSideStuff()}
            </LoginCard>
        </GradientBackground>
    );
};

export default ForgotPassword;
