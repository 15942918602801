import LinkedIn from "@mui/icons-material/LinkedIn";
import { MentorCardElement } from "./PreviewCardElements";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Grow from "@mui/material/Grow";
import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import WhatsappOutlinedIcon from "@mui/icons-material/WhatsApp";

const PreviewCardWidget = ({ mentor }) => {
    const [selected, setSelected] = useState(false);

    const handleLinkedInClick = () => {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = mentor?.linkedin;
        a.click();
    };

    return (
        <MentorCardElement.Card
            selected={selected}
            onClick={() => setSelected(!selected)}
        >
            <Grow in={selected && mentor?.availability}>
                <MentorCardElement.DateTopRight>
                    {mentor?.availability}
                </MentorCardElement.DateTopRight>
            </Grow>

            <MentorCardElement.Image
                selected={selected}
                src={mentor?.img_url_web || `/avatar.webp`}
            />
            <MentorCardElement.Body>
                <MentorCardElement.Title
                    fontFamily={"Outfit"}
                    selected={selected}
                >
                    {mentor?.name}
                </MentorCardElement.Title>
                <MentorCardElement.Details fontFamily={"Outfit"}>
                    {mentor?.bio?.split("\\n").map((text) => (
                        <React.Fragment key="text">
                            <>
                                {text} <br />
                            </>
                        </React.Fragment>
                    ))}
                </MentorCardElement.Details>
            </MentorCardElement.Body>
            <MentorCardElement.Action selected={selected}>
                <MentorCardElement.Button
                    id="linkedin-btn-collegeit"
                    iconcolor="#0077b5"
                    selected={!selected}
                    onClick={handleLinkedInClick}
                >
                    <LinkedIn />
                </MentorCardElement.Button>
                <MentorCardElement.Button
                    id="whatsapp-btn-collegeit"
                    iconcolor="#128C7E"
                    selected={!selected}
                    // onClick={handleWhatsappClick}
                >
                    <WhatsAppIcon />
                </MentorCardElement.Button>
            </MentorCardElement.Action>
        </MentorCardElement.Card>
    );
};

export const PreviewCardSite = ({ mentor }) => {
    return (
        <>
            <Grid item>
                <Box
                    style={{
                        width: "287px",
                        height: "364px",
                        background: "#fff",
                        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                        marginBottom: "1.2rem",
                    }}
                >
                    <Box
                        style={{
                            height: "135px",
                            background: "#1DA1F2",
                        }}
                    >
                        <Box
                            style={{
                                background: "white",
                                width: "100px",
                                height: "100px",
                                marginTop: "48px",
                                borderRadius: "50%",
                                padding: "2px",
                                transform: "translate(92px,-40px)",
                            }}
                        >
                            <img
                                src={mentor?.img_url_web || `/avatar.webp`}
                                alt="pro"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "-5px",
                            }}
                        >
                            <h3
                                style={{
                                    marginTop: "-25px",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    color: "white",
                                    marginBottom: "5px",
                                    fontFamily: "'Outfit'",
                                }}
                            >
                                {mentor?.name}
                            </h3>
                            <a
                                href={mentor?.linkedin}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button
                                    style={{
                                        background: "none",
                                        border: "1.5px solid white",
                                        borderRadius: "25px",
                                        color: "#fff",
                                        padding: "3px 22px",
                                        marginTop: "5px",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                    }}
                                >
                                    Linkedin Profile
                                </button>
                            </a>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        spacing={1}
                        sx={{ padding: "0.8rem 2rem" }}
                    >
                        <Box>
                            <Typography
                                variant="paragraph"
                                color="#4B4A4A"
                                fontFamily="'Outfit'"
                                fontSize={10}
                            >
                                UNIVERSITY
                            </Typography>
                            <abbr
                                title={mentor?.university}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    variant="h6"
                                    fontSize={15}
                                    fontWeight={500}
                                    fontFamily="'Outfit'"
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {mentor?.university}
                                </Typography>
                            </abbr>
                        </Box>
                        <Box>
                            <Typography
                                variant="paragraph"
                                color="#4B4A4A"
                                fontFamily="'Outfit'"
                                fontSize={10}
                            >
                                SPECIALIZATION
                            </Typography>
                            <abbr
                                title={mentor?.specialization}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    variant="h6"
                                    fontSize={15}
                                    fontWeight={500}
                                    fontFamily="'Outfit'"
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {mentor?.specialization}
                                </Typography>
                            </abbr>
                        </Box>
                        <Box>
                            <Typography
                                variant="paragraph"
                                color="#4B4A4A"
                                fontFamily="'Outfit'"
                                fontSize={10}
                            >
                                COUNTRY
                            </Typography>
                            <Typography
                                variant="h6"
                                fontSize={15}
                                fontWeight={500}
                                fontFamily="'Outfit'"
                            >
                                {mentor?.country}
                            </Typography>
                        </Box>
                        <Button
                            style={{
                                color: "white",
                                backgroundColor: "#25D366",
                                textTransform: "none",
                                fontFamily: "'Outfit', Montserrat",
                                fontSize: "13px",
                                fontWeight: "500",
                                borderRadius: "0.4rem",
                                letterSpacing: "-0.16px",
                            }}
                            // onClick={handleOpen}
                        >
                            <WhatsappOutlinedIcon />
                            &nbsp;&nbsp;Start WhatsApp Chat
                        </Button>
                    </Stack>
                </Box>
            </Grid>
        </>
    );
};

export default PreviewCardWidget;
