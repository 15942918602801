import { useEffect, useState } from "react";
import { Table } from "./Overview/OverviewElements";
import LeadTablePagination from "./Overview/LeadTablePagination";
import unimap from "../utils/unimap";
import moment from "moment/moment";
import { useGlobalContext } from "../contexts/GlobalContext";
// import { MdDeleteOutline } from "react-icons/md";
// import { IconButton } from "@mui/material";
// import { FiCheck } from "react-icons/fi";
// import { IoIosRefresh } from "react-icons/io";

const MeetingRequestsTable = ({ leads = [], toggleMeet, deleteMeet }) => {
    const rowsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(leads.length);

    const [state, dispatch] = useGlobalContext();
    useEffect(() => {
        setTotalPage(Math.ceil(leads.length / rowsPerPage));
    }, [leads]);

    const handleNextPageClick = () => {
        if (currentPage === totalPage - 1) return;
        setCurrentPage((prev) => prev + 1);
    };

    const handlePrevPageClick = () => {
        if (currentPage === 0) return;
        setCurrentPage((prev) => prev - 1);
    };

    return (
        <>
            <Table.Wrapper>
                <thead>
                    <tr style={{ borderBottom: "1px solid #d2d2d2" }}>
                        <Table.Head>Email</Table.Head>
                        <Table.Head>Country</Table.Head>
                        {state.user?.privilege === "admin" && (
                            <Table.Head>University</Table.Head>
                        )}
                        <Table.Head>Timestamp</Table.Head>
                        {/* <Table.Head>Status</Table.Head> */}
                        {/* <Table.Head>Action</Table.Head> */}
                    </tr>
                </thead>
                <tbody>
                    {leads
                        .slice(
                            currentPage * rowsPerPage,
                            currentPage * rowsPerPage + rowsPerPage
                        )
                        .map((lead, index) => {
                            return (
                                <Table.Row key={lead._id}>
                                    <Table.Data style={{ width: "25%" }}>
                                        {lead.email}
                                    </Table.Data>
                                    <Table.Data style={{ width: "18%" }}>
                                        {lead.country}
                                    </Table.Data>
                                    {state.user?.privilege === "admin" && (
                                        <Table.Data style={{ width: "16%" }}>
                                            {lead.university}
                                        </Table.Data>
                                    )}
                                    <Table.Data style={{ width: "16%" }}>
                                        {moment(lead.timestamp).format(
                                            "Do MMM YYYY"
                                        )}
                                    </Table.Data>
                                    {/* <Table.Data style={{ width: "10%" }}>
                                        {lead.resolved ? "Resolved" : "Waiting"}
                                    </Table.Data> */}
                                    {/* <Table.Data
                                        style={{
                                            width: "15%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <IconButton
                                            sx={{
                                                background: "#89ca91",
                                                marginRight: "10px",
                                                "&:hover": {
                                                    background: "#149355",
                                                },
                                            }}
                                            onClick={() => toggleMeet(lead._id)}
                                        >
                                            {!lead.resolved ? (
                                                <FiCheck
                                                    style={{
                                                        color: "white",
                                                        fontSize: "18px",
                                                    }}
                                                />
                                            ) : (
                                                <IoIosRefresh
                                                    style={{
                                                        color: "white",
                                                        fontSize: "18px",
                                                    }}
                                                />
                                            )}
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                background: "#f67272",
                                                "&:hover": {
                                                    background: "#c73131",
                                                },
                                            }}
                                            onClick={() => deleteMeet(lead._id)}
                                        >
                                            <MdDeleteOutline
                                                style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                }}
                                            />
                                        </IconButton>
                                    </Table.Data> */}
                                </Table.Row>
                            );
                        })}
                </tbody>
            </Table.Wrapper>
            <LeadTablePagination
                currentPage={currentPage + 1}
                totalPage={totalPage}
                onPreviousClick={() => handlePrevPageClick()}
                onNextClick={() => handleNextPageClick()}
            />
        </>
    );
};

export default MeetingRequestsTable;
